import "../App.css";
import {
  getCountriesJson,
  getSelectedBorderViolenceJson,
  getFullContinentDataJson,
  getIDEventTypeJson,
} from "../utils/csv";
import React, { useState, useEffect } from "react";
import {
  ListItemText,
  List,
  ListItemIcon,
  Container,
  Typography,
  ListItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import MapView from "../components/Map";
import ControlPanel from "../components/ControlPanel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactECharts from "echarts-for-react";
import PlayButtonBorder from "../components/PlayButtonBorder";
import moment from "moment";
import buffer from "@turf/buffer";
import bufferRCS from "../utils/buffer-rcs.json";
import bufferRLC from "../utils/buffer-rlc.json";
import bufferAll from "../utils/buffer-all.json";
import { useTranslation } from "react-i18next";
import StyledButton from "../components/StyledButton";
import theme from "../utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import * as d3 from "d3";

const useStyles = makeStyles((theme) => ({
  bigContainer: {
    padding: "10px 20px",
    background: "#FCFDF3",
    '@media (max-width: 720px)': { // Media query for mobile devices (less than 760px)
      padding: "10px 7px", // Reduce padding in bigContainer in mobile devices 
    },
  },
  container: {
    width: "100%",
    height: "100%",
    fontFamily: "'Libre Franklin', sans-serif",
    boxSizing: "border-box",
    background: "#FCFDF3",
  },
  controlPanelContainer: {
    display: "flex",
  },
  title: {
    margin: 0,
  },
  text: {
    width: "60%",
    fontFamily: "'Libre Franklin', sans-serif",
  },
  chartContainer: {
    display: "flex !important",
  },
  chartCard: {
    "& .echarts-for-react": {
      width: "100%",
    },
  },
  chartTitle: {
    textTransform: "none",
    color: "#35536C",
    fontSize: 20,
    margin: "24px 0",
  },
  chartTitleHighlight: {
    textTransform: "none",
    color: "#E39659",
    fontSize: 20,
    margin: "24px 0 10px 0",
  },
  chartSources: {
    color: "#35536C",
    fontSize: ".8rem",
    paddingLeft: 5,
    marginTop: 10,
  },
}));

const parseHTML = require("html-react-parser");

function Border(props) {
  const [borderViolence, setBorderViolence] = useState(null);
  const [year, setYear] = useState(2025);
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const [years, setYears] = useState([]);
  const [region, setRegion] = useState("");
  const [fullContinentCounts, setFullContinentCounts] = useState([]);
  const [regions, setRegions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [optionsSelector, setOptionsSelector] = useState([]);
  const [filter, setFilter] = useState({ year: null, region: "" });
  const [play, setPlay] = useState(false);
  const [selectedGraphData, setSelectedGraphData] = useState(null);
  const classes = useStyles();
  const [date, setDate] = useState(moment());
  const [accumulatedEvents, setAccumulatedEvents] = useState([
    "any",
    ["in", year, ["get", "event_date"]],
  ]);
  const [dataBufferAll, setDataBufferAll] = useState();
  const [dataBufferAll50, setDataBufferAll50] = useState();
  const [dataBufferAll100, setDataBufferAll100] = useState();
  const [dataBufferAll150, setDataBufferAll150] = useState();
  const [dataBufferRCS, setDataBufferRCS] = useState();
  const [dataBufferRCS50, setDataBufferRCS50] = useState();
  const [dataBufferRCS100, setDataBufferRCS100] = useState();
  const [dataBufferRCS150, setDataBufferRCS150] = useState();
  const [dataBufferRLC, setDataBufferRLC] = useState();
  const [dataBufferRLC50, setDataBufferRLC50] = useState();
  const [dataBufferRLC100, setDataBufferRLC100] = useState();
  const [dataBufferRLC150, setDataBufferRLC150] = useState();
  const [eventTypes, setEventType] = useState();
  const [showBufferZones, setShowBufferZones] = useState(true);
  const [shownDate, setShownDate] = useState();
  const [floatingDateOpacity, setFloatingDateOpacity] = useState("0");
  const lng =
    window.location.hash !== "" ? window.location.hash.split("/")[1] : "en";
  const { t } = useTranslation();

  let RLC_name;
  let RCS_name;
  if (regions) {
    RLC_name = regions.find((country) => country.country_id === "RLC")[
      `country_name_${lng}`
    ];
    RCS_name = regions.find((country) => country.country_id === "RCS")[
      `country_name_${lng}`
    ];
  }

  const FloatingSliderDate = d3.select(".MuiSlider-thumb");
  FloatingSliderDate.style("position", "relative")
    .insert("p")
    .attr("class", "floating-date")
    .style("font-size", "14px");
  d3.select(".floating-date")
    .style("opacity", floatingDateOpacity)
    .style("transition", "opacity .5s ease-in")
    .style("position", "absolute")
    .style("bottom", "-45px")
    .style("color", "#35536C")
    .text(shownDate);

  const [viewport, setViewport] = useState({
    longitude: 1.2778,
    latitude: 12.3512,
    zoom: 3.5,
  });

  function createDataLayerBuffer(idLayer, km, idRegion) {
    return {
      id: idLayer,
      type: "fill",
      paint: {
        "fill-color": `${
          bufferZonesColor.find((buffer) => buffer.km === km).color
        }`,
      },
      layout: {
        visibility:
          showBufferZones && filter.region === idRegion ? "visible" : "none",
      },
    };
  }

  const eventTypeColor = [
    ["1", "#35536C"],
    ["2", "#DB5A47"],
    ["3", "#FFD748"],
  ];
  const borderTypeColor = [
    ["1", "#2a4357"],
    ["2", "#943f32"],
    ["3", "#9e862e"],
  ];

  const bufferZonesColor = [
    { km: 2, color: "#5C5D61" },
    { km: 10, color: "#78987E" },
    { km: 50, color: "#AABDA9" },
    { km: 100, color: "#CBD6CD" },
  ];

  const numberEventsSteps = [
    { number: 1, step: 10 },
    { number: 10, step: 15 },
    { number: 50, step: 20 },
    { number: 200, step: 25 },
  ];

  const dataLayerBorder = {
    id: "borderViolence",
    type: "circle",
    filter: [
      "all",
      accumulatedEvents,
      ["in", filter.region, ["get", "study_area"]],
      ["!=", 0, ["to-number", ["get", "fatalities"]]],
    ],
    paint: {
      "circle-radius": [
        "step",
        ["to-number", ["get", "fatalities"]],
        5,
        10,
        10,
        50,
        15,
        200,
        20,
      ],
      "circle-color": {
        property: "event_type",
        type: "categorical",
        stops: eventTypeColor,
      },
      "circle-opacity": 0.7,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": {
        property: "event_type",
        type: "categorical",
        stops: eventTypeColor,
      },
    },
  };

  const getYears = () => {
    if (borderViolence) {
      borderViolence.features.map((feature) => {
        let date = new Date(feature.properties.event_date);

        if (!isNaN(date.getFullYear())) {
          return setYears(years.push(date.getFullYear()));
        }
      });
      return setYears(
        years.filter((item, index) => {
          return years.indexOf(item) === index;
        })
      );
    }
  };
  const getEventsByYear = (y, features = borderViolence) => {
    let totalEvents = 0;
    features.features
      .filter((feature) => feature.properties.event_date.includes(y))
      .map(() => {
        totalEvents += 1;
      });
    return totalEvents;
  };

  const getFatalitiesByYear = (y, features = borderViolence) => {
    let totalFatalities = 0;
    features.features
      .filter((feature) => feature.properties.event_date.includes(y))
      .map((feature) => {
        totalFatalities += parseInt(feature.properties.fatalities);
      });
    return totalFatalities;
  };

  const getBorderViolence = (region) => {
    var bv = { features: [] };
    if (!borderViolence) return [];
    borderViolence.features.forEach((feature) => {
      if (feature && feature.properties.study_area === region)
        bv.features.push(feature);
    });
    return bv;
  };

  useEffect(() => {
    if (!loading) {
      setOptionsSelector(
        regions.map((region) => {
          let region_name;
          lng === "en"
            ? (region_name = region.country_name_en)
            : (region_name = region.country_name_fr);
          return { value: region.country_id, label: region_name };
        })
      );
      function createBufferZones(buffers, kms) {
        return buffer(buffers, kms, { units: "kilometers" });
      }
      return (
        setDataBufferAll(createBufferZones(bufferAll, -9)),
        setDataBufferAll50(createBufferZones(bufferAll, 9)),
        setDataBufferAll100(createBufferZones(bufferAll, 49)),
        setDataBufferAll150(createBufferZones(bufferAll, 99)),
        setDataBufferRCS(createBufferZones(bufferRCS, -9)),
        setDataBufferRCS50(createBufferZones(bufferRCS, 9)),
        setDataBufferRCS100(createBufferZones(bufferRCS, 49)),
        setDataBufferRCS150(createBufferZones(bufferRCS, 99)),
        setDataBufferRLC(createBufferZones(bufferRLC, -9)),
        setDataBufferRLC50(createBufferZones(bufferRLC, 9)),
        setDataBufferRLC100(createBufferZones(bufferRLC, 49)),
        setDataBufferRLC150(createBufferZones(bufferRLC, 99)),
        getYears(),
        setMaxYear(Math.max(...years)),
        setMinYear(Math.min(...years)),
        setYear(Math.max(...years)),
        setDate(moment(`${Math.max(...years)}-01`, "YYYY/MM")),
        setShownDate(year)
      );
    }
  }, [loading]);

  useEffect(() => {
    setAccumulatedEvents(["any", ["in", year, ["get", "event_date"]]]);
    setShownDate(year);
  }, [year]);

  useEffect(() => {
    if (!borderViolence) return;
    var yearsGraphs = [...new Set(years.sort())];

    var features;

    if (yearsGraphs.size <= 0) return;

    if (filter.region.length > 0) {
      features = getBorderViolence(filter.region);
    } else {
      features = borderViolence;
    }

    const featuresRLC = getBorderViolence("RLC");
    const featuresRCS = getBorderViolence("RCS");

    const fatalities = fullContinentCounts
      .sort((a, b) => parseInt(a.year) - parseInt(b.year))
      .map((c) => c.fatalities);
    const events = fullContinentCounts
      .sort((a, b) => parseInt(a.year) - parseInt(b.year))
      .map((c) => c.events);

    var fatalitiesRLC = yearsGraphs.map((y) =>
      getFatalitiesByYear(y, featuresRLC)
    );
    // if (featuresRLC.features)
    //   fatalitiesRLC.push(getFatalitiesByYear("2021", featuresRLC));

    var eventsRLC = yearsGraphs.map((y) => getEventsByYear(y, featuresRLC));
    // if (featuresRLC.features)
    //   eventsRLC.push(getEventsByYear("2021", featuresRLC));

    var fatalitiesRCS = yearsGraphs.map((y) =>
      getFatalitiesByYear(y, featuresRCS)
    );
    // if (featuresRCS.features)
    //   fatalitiesRCS.push(getFatalitiesByYear("2021", featuresRCS));

    var eventsRCS = yearsGraphs.map((y) => getEventsByYear(y, featuresRCS));
    // if (featuresRCS.features)
    //   eventsRCS.push(getEventsByYear("2021", featuresRCS));

    var fatalitiesRest = fatalities
      .map(
        (e, i) =>
          parseInt(e) - parseInt(fatalitiesRLC[i]) - parseInt(fatalitiesRCS[i])
      )
      .map((a, j) => {
        return [yearsGraphs[j] + "/01/01", a];
      });
    // if (fatalitiesRest)
    //   fatalitiesRest.push([
    //     "2022/01/01",
    //     fatalitiesRest[fatalitiesRest.length - 1][1],
    //   ]);

    var eventsRest = events
      .map(
        (e, i) => parseInt(e) - parseInt(eventsRLC[i]) - parseInt(eventsRCS[i])
      )
      .map((a, j) => {
        return [yearsGraphs[j] + "/01/01", a];
      });

    // if (eventsRest)
    //   eventsRest.push(["2022/01/01", eventsRest[eventsRest.length - 1][1]]);

    yearsGraphs = [...new Set(years.sort())];

    const markLine = filter
      ? {
          animation: false,
          symbol: ["none", "none"],
          silent: true,
          label: {
            formatter: (p) => p.data.value.split("/")[0],
            padding: [0, 0, 0, 40],
            fontWeight: 800,
            color: "#35536C",
          },
          data: [
            {
              xAxis: moment(date).isAfter("2024-12-31")
                ? "2025"
                : moment(date).format("YYYY"),
            },
          ],
          lineStyle: {
            color: "#35536C",
            shadowColor: "#35536C",
            shadowOffsetY: -18,
          },
        }
      : {};

    const optionEvents = {
      color: ["#C36628", "#225C72", "#97BD99"],
      grid: {
        bottom: window.innerWidth < 1203 && lng === "fr" ? 20 : 30,
        left: 52,
        top: window.innerWidth < 1203 && lng === "fr" ? 100 : 60,
      },
      tooltip: {
        padding: 20,
        formatter: function (params) {
          if (params.data[0].includes("2025")) return null;
          const perc = (
            (params.data[1] / parseInt(events[params.dataIndex])) * 100
          ).toFixed(2);
          return (
            "<b style='display: block; text-align: center'>" +
            params.data[0].split("/")[0] +
            "</b>" +
            params.marker +
            "<span style='padding-left: 12px; '></span>" +
            params.data[1] +
            // (params.seriesIndex !== 2 ? " (" + perc + "%)" : "")
            (" (" + perc + "%)")
          );
        },
      },
      legend: {
        icon: "circle",
        left: 0,
      },
      xAxis: {
        type: "time",
        max: 3600 * 1000 * 26 * 365 * 52, // 25 years after the first year(1997) until 2024
      },
      yAxis: {
        axisLabel: { align: "left", padding: [0, 0, 0, -40] },
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString(lng === "fr" ? "de" : lng);
          },
        },
      },
      series: [
        {
          name: `${RLC_name}`,
          type: "line",
          stack: "Total",
          smooth: true,
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: eventsRLC.map((a, j) => {
            return [yearsGraphs[j] + "/01/01", a];
          }),
          markLine: markLine,
          symbol: "circle",
          symbolSize: 5,
        },
        {
          name: `${RCS_name}`,
          type: "line",
          stack: "Total",
          smooth: true,
          areaStyle: {},
          symbol: "circle",
          symbolSize: 5,
          emphasis: {
            focus: "series",
          },
          data: eventsRCS.map((a, j) => {
            return [yearsGraphs[j] + "/01/01", a];
          }),
        },
        {
          name: t(`border.africaRest`),
          type: "line",
          stack: "Total",
          smooth: true,
          areaStyle: {},
          symbol: "circle",
          symbolSize: 5,
          emphasis: {
            focus: "series",
          },
          data: eventsRest,
        },
      ],
      textStyle: {
        fontFamily: '"Libre Franklin", sans-serif',
        color: "#35536C",
      },
    };

    const optionFatalities = {
      color: ["#C36628", "#225C72", "#97BD99"],
      grid: {
        bottom: window.innerWidth < 1203 && lng === "fr" ? 20 : 30,
        left: 52,
        top: window.innerWidth < 1203 && lng === "fr" ? 100 : 60,
      },
      tooltip: {
        padding: 20,
        formatter: function (params) {
          if (params.data[0].includes("2025")) return null;
          const perc = (
            (params.data[1] / parseInt(fatalities[params.dataIndex])) * 100
          ).toFixed(2);
          return (
            "<b style='display: block; text-align: center'>" +
            params.data[0].split("/")[0] +
            "</b>" +
            params.marker +
            "<span style='padding-left: 24px; '></span>" +
            params.data[1] +
            // (params.seriesIndex !== 2 ? " (" + perc + "%)" : "")
            (" (" + perc + "%)")
          );
        },
      },
      legend: {
        icon: "circle",
        left: 0,
      },
      xAxis: {
        type: "time",
        max: 3600 * 1000 * 26 * 365 * 52, // 26 years after the first year(1997) until 2024
      },
      yAxis: {
        axisLabel: { align: "left", padding: [0, 0, 0, -40] },
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString(lng === "fr" ? "de" : lng);
          },
        },
      },
      series: [
        {
          name: `${RLC_name}`,
          type: "line",
          stack: "Total",
          smooth: true,
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: fatalitiesRLC.map((a, j) => {
            return [yearsGraphs[j] + "/01/01", a];
          }),
          markLine: markLine,
          symbol: "circle",
          symbolSize: 5,
        },
        {
          name: `${RCS_name}`,
          type: "line",
          stack: "Total",
          smooth: true,
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: fatalitiesRCS.map((a, j) => {
            return [yearsGraphs[j] + "/01/01", a];
          }),
          symbol: "circle",
          symbolSize: 5,
        },
        {
          name: t(`border.africaRest`),
          type: "line",
          stack: "Total",
          smooth: true,
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: fatalitiesRest,
          symbol: "circle",
          symbolSize: 5,
        },
      ],
      textStyle: {
        fontFamily: '"Libre Franklin", sans-serif',
        color: "#35536C",
      },
    };

    setSelectedGraphData({
      events: optionEvents,
      fatalities: optionFatalities,
    });
  }, [years, date, filter]);

  useEffect(() => {
    async function getCountries() {
      try {
        const parsedData = await getCountriesJson();
        return setRegions(
          parsedData.filter(
            (data) => data.country_id === "RCS" || data.country_id === "RLC"
          )
        );
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    async function getSelectedBorderViolence() {
      try {
        const parsedData = await getSelectedBorderViolenceJson();
        return setBorderViolence(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    async function getEventType() {
      try {
        const parsedData = await getIDEventTypeJson();
        return setEventType(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    async function getFullContinentData() {
      try {
        const parsedData = await getFullContinentDataJson();
        return setFullContinentCounts(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    Promise.all([
      getCountries(),
      getSelectedBorderViolence(),
      getFullContinentData(),
      getEventType(),
    ])
      .then((values) => {
        setLoading(false);
        return values;
      })
      .catch((reason) => {
        console.log(reason);
      });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <div className={classes.bigContainer}>
          <Box
            sx={{
              display: "flex",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <div className={classes.bigContainer}>
          <Typography variant="h2" gutterBottom={true} sx={{mb:3}}>
            {region
              ? t("border.map.title_region") +
                " " +
                optionsSelector.find((option) => option.value === region).label
              : parseHTML(t("border.map.title"))}{" "}
          </Typography>
          <ControlPanel
            optionsSelector={optionsSelector}
            funcSelector={setRegion}
            valueSelector={region}
            min={minYear}
            max={maxYear}
            year={year}
            setYear={setYear}
            region={region}
            filter={filter}
            setFilter={setFilter}
            play={play}
            setPlay={setPlay}
            date={date}
            setDate={setDate}
            setFloatingDateOpacity={setFloatingDateOpacity}
            setAccumulatedEvents={setAccumulatedEvents}
            accumulatedEvents={accumulatedEvents}
            setShownDate={setShownDate}
            shownDate={shownDate}
            page={"border"}
            className="remove-from-download"
            lng={lng}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setShowBufferZones(!showBufferZones)}
                  size="small"
                />
              }
              label={t(`border.map.buttons.hideBufferZones`)}
              aria-label={t(`border.map.buttons.hideBufferZonesAria`)}
              className="remove-from-download"
            />
          </FormGroup>
          <div className={classes.container} id="data-png">
            {/* Map container */}
            <Container
              disableGutters={true}
              sx={{ overflow: "hidden" }}
              variant="border"
              id="map-container"
            >
              <MapView
                viewport={viewport}
                setViewport={setViewport}
                data={[
                  [
                    dataBufferAll150,
                    createDataLayerBuffer("bufferAll150", 100, ""),
                  ],
                  [
                    dataBufferAll100,
                    createDataLayerBuffer("bufferAll100", 50, ""),
                  ],
                  [
                    dataBufferAll50,
                    createDataLayerBuffer("bufferAll50", 10, ""),
                  ],
                  [dataBufferAll, createDataLayerBuffer("bufferAll", 2, "")],
                  [
                    dataBufferRCS150,
                    createDataLayerBuffer("bufferRCS150", 100, "RCS"),
                  ],
                  [
                    dataBufferRCS100,
                    createDataLayerBuffer("bufferRCS100", 50, "RCS"),
                  ],
                  [
                    dataBufferRCS50,
                    createDataLayerBuffer("bufferRCS50", 10, "RCS"),
                  ],
                  [dataBufferRCS, createDataLayerBuffer("bufferRCS", 2, "RCS")],
                  [
                    dataBufferRLC150,
                    createDataLayerBuffer("bufferRLC150", 100, "RLC"),
                  ],
                  [
                    dataBufferRLC100,
                    createDataLayerBuffer("bufferRLC100", 50, "RLC"),
                  ],
                  [
                    dataBufferRLC50,
                    createDataLayerBuffer("bufferRLC50", 10, "RLC"),
                  ],
                  [dataBufferRLC, createDataLayerBuffer("bufferRLC", 2, "RLC")],
                  [borderViolence, dataLayerBorder],
                ]}
                filterRegion={filter.region}
                region={region}
                countries={regions}
                page="border"
                eventTypes={eventTypes}
                lng={lng}
              />
            </Container>
            {/* Map's references container */}
            <Container variant="border" sx={{ px: { xs: 1, sm: 8 } }}>
              <Grid container spacing={2} sx={{ py: 4 }}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h3"
                    gutterBottom={true}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                      color: "#35536C",
                    }}
                  >
                    {t("border.map.references.title")}
                  </Typography>
                  <List>
                    {eventTypes.map((event) => (
                      <ListItem key={`${event.id}`}>
                        <ListItemIcon sx={{ pr: 1 }}>
                          <CircleIcon
                            sx={{
                              color: eventTypeColor.find(
                                (evt) => evt[0] === event.id
                              ),
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={lng === "en" ? event.name_en : event.name_fr}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h3"
                    gutterBottom={true}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                      color: "#35536C",
                    }}
                  >
                    {t("border.map.references.numberEvents.title")}
                  </Typography>
                  <List>
                    {numberEventsSteps.map((number) => (
                      <ListItem key={`key-${number.number}`}>
                        <ListItemIcon sx={{ width: "2rem" }}>
                          <CircleOutlinedIcon sx={{ fontSize: number.step }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={t(
                            `border.map.references.numberEvents.${number.number}`
                          )}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ opacity: `${showBufferZones ? 1 : 0.5}` }}
                >
                  <Typography
                    variant="h3"
                    gutterBottom={true}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                      color: "#35536C",
                    }}
                  >
                    {t("border.map.references.bufferZones.title")}
                  </Typography>
                  <List>
                    {bufferZonesColor.map(
                      (buffer) =>
                        buffer.km !== 2 && (
                          <ListItem key={`key-${buffer.km}`}>
                            <ListItemIcon sx={{ pr: 1 }}>
                              <SquareIcon
                                sx={{
                                  color: buffer.color,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={t(
                                `border.map.references.bufferZones.${buffer.km}`
                              )}
                            />
                          </ListItem>
                        )
                    )}
                  </List>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  pt: 2,
                  pb: 4,
                  color: "#927349",
                  fontSize: ".8rem",
                  fontWeight: 600,
                }}
              >
                {parseHTML(t("mapDisclaimer"))}
              </Typography>
            </Container>
            {/* Play button container */}
            

            {/* Charts container */}
            <Grid
              className={classes.container}
              sx={{ borderTop: "1px solid #C9B99F" }}
            >
              {selectedGraphData && (
                <Grid
                  sx={{ px: 1 }}
                  container
                  spacing={2}
                  className={classes.chartContainer}
                >
                  <Grid item xs={12} sm={6} className={classes.chartCard}>
                    <h6 className={classes.chartTitleHighlight}>
                      {t("border.chartEvents.titleHighlight")}{" "}
                      <span className={classes.chartTitle}>
                        {t("border.chartEvents.title")}
                      </span>
                    </h6>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2A2A35",
                        fontFamily: "Libre Franklin",
                        fontSize: 14,
                        fontWeight: 400,
                        mb: { xs: 2 },
                      }}
                    >
                      {t("border.chartEvents.description")}
                    </Typography>
                    <ReactECharts
                      option={selectedGraphData.events}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"theme_name"}
                    />
                    <p className={classes.chartSources}>
                      {t("border.chartEvents.sources")}
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={6} className={classes.chartCard}>
                    <h6 className={classes.chartTitleHighlight}>
                      {t("border.chartFatalities.titleHighlight")}{" "}
                      <span className={classes.chartTitle}>
                        {t("border.chartFatalities.title")}
                      </span>
                    </h6>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2A2A35",
                        fontFamily: "Libre Franklin",
                        fontSize: 14,
                        fontWeight: 400,
                        mb: { xs: 2 },
                      }}
                    >
                      {t("border.chartFatalities.description")}
                    </Typography>
                    <div>
                      <ReactECharts
                        option={selectedGraphData.fatalities}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                      />
                    </div>

                    <p className={classes.chartSources}>
                      {t("border.chartFatalities.sources")}
                    </p>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Box sx={{ mt: 3, position: "relative" }}>
              <Box
                className="remove-from-download"
                style={{
                  backgroundColor: "#FCFDF3",
                  zIndex: "99",
                  position: "sticky",
                }}
              >
                <p style={{ visibility: "hidden" }}>
                  {t("downloadDisclaimer")}
                </p>
              </Box>

              <p
                style={{
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  zIndex: "9",
                }}
              >
                {t("downloadDisclaimer")}
              </p>
            </Box>
          </div>
          <div className={classes.container}>
            {/* Dinamic text container */}
            <StyledButton
                  text={t("buttons.downloadImage")}
                  icon={true}
                  page="border"
                />
          </div>
        </div>
      )}
    </ThemeProvider>
  );
}
export default Border;

