import {
  countriesCSV,
  comparativeCSV,
  selectedBorderViolenceCSV,
  geometrySCDBIDB_CSV,
  UNPIVOT_SCDIDB_CSV,
  ID_Event_Type_CSV,
  ID_SCDI_CSV
} from "./spreadsheet.js";
import wkt from "wellknown";
const csv = require("csvtojson");

const LOCAL = true;
const PATH = window.location.hostname === "localhost" ? "././" : "./"

export async function getCountriesJson() {
  if (LOCAL){
    const response = await fetch( PATH + 'csv/COUNTRY_SCDI.csv' ).then( response => response.text() )
    return csv().fromString(response);
  }
  const response = await countriesCSV();
  return csv().fromString(response.data);
};

export async function getIDEventTypeJson (){
  if (LOCAL){
    const response = await fetch( PATH + 'csv/ID_EVENT_TYPE.csv' ).then( response => response.text() )
    return csv().fromString(response);
  }
  const response = await ID_Event_Type_CSV();
  return csv().fromString(response.data);
};

export async function getID_SCDI_Json (){
  if (LOCAL){
    const response = await fetch( PATH + 'csv/ID_SCDI.csv' ).then( response => response.text() )
    return csv().fromString(response);
  }
  const response = await ID_SCDI_CSV();
  return csv().fromString(response.data);
};

export async function getFullContinentDataJson() {
  if (LOCAL){
    const response = await fetch( PATH + 'csv/SELECTED_BORDER_TOTAL_COMPARATIVE.csv' ).then( response => response.text() )
    return csv().fromString(response);
  }
  const response = await comparativeCSV();
  return csv().fromString(response.data);
}

export async function getSelectedBorderViolenceJson() {
  var response;
  var selectedBorderViolenceJson;

  if (LOCAL){
    response = await fetch( PATH + 'csv/SELECTED_BORDER_VIOLENCE.csv' ).then( response => response.text() )
    selectedBorderViolenceJson = await csv().fromString(response);
  } else {
    response = await selectedBorderViolenceCSV();
    selectedBorderViolenceJson = await csv().fromString(response.data);
  }

  var GeoJSON = require("geojson");
  const selectedBorderViolenceGeoJson = GeoJSON.parse(
    selectedBorderViolenceJson,
    {
      Point: ["latitude", "longitude"],
    }
  );
  return selectedBorderViolenceGeoJson;
}

export async function getUNPIVOT_SCDIDB_Json() {
  if (LOCAL){
    const response = await fetch( PATH + 'csv/UNPIVOT_SCDI.csv' ).then( response => response.text() )
    return csv().fromString(response);
  }
  const response = await UNPIVOT_SCDIDB_CSV();
  return csv().fromString(response.data);
}

export async function getGeometrySCDBIDBGeoJson() {
  var GeoJSON = require("geojson");

  var response;
  if (LOCAL){
    response = await fetch( PATH + 'csv/GEOMETRY_SCDI_WKT.csv' ).then( response => response.text() )
  } else {
    response = await geometrySCDBIDB_CSV();
  }

  const extraproperties = await getUNPIVOT_SCDIDB_Json();

  const extrapropertiesObj = {};
  for (const key of extraproperties) {
    if (key.SCDI !== "") {
      if (!extrapropertiesObj[key.ID]) {
        extrapropertiesObj[key.ID] = {};
      }
      extrapropertiesObj[key.ID]["y" + key.year] = key.SCDI;
    }
  }

  const geometrySCDBIDB_Json = (await csv().fromString(LOCAL ? response : response.data)).map(
    (item) => {
     item.polygon = wkt.parse(item.WKT).coordinates[0];
      delete item.WKT;
      if (extrapropertiesObj[item.ID]) {
        item = { ...item, ...extrapropertiesObj[item.ID] };
      }
      return item;
    }
  );
  const geometrySCDBIDB_GeoJson = GeoJSON.parse(geometrySCDBIDB_Json, {
    Polygon: "polygon",
  });

  return geometrySCDBIDB_GeoJson;
}

export function transformCountryDataForGraphs(data){
  var allRegions = []

   data.features.map((f) => f.properties).filter((a) => Object.keys(a).length >= 5).forEach((conflict) => {
    var country = conflict.country_id
    var study_area = conflict.study_area
    var years = Object.keys(conflict).filter((k) => k[0] === "y" && k.length === 5).map((y) => y.substring(1))

    var exists = allRegions.filter((a) => a.country === conflict.country_id)
    if (exists.length <= 0){
      allRegions.push({country: country, years: years.map((y) => {return {year: y, type: conflict["y" + y], count: 1}})})
    } else {
      years.forEach((newYear) => {
        var yearExists = exists[0].years.filter((y) => y.year === newYear && y.type === conflict["y" + newYear])
        if (yearExists.length > 0){
          yearExists[0].count = yearExists[0].count + 1
        } else {
          exists[0].years.push({year: newYear, type: conflict["y" + newYear], count: 1})

        }
      })
    }

  if (study_area.length >0){
    var exists = allRegions.filter((a) => a.country === study_area)
    if (exists.length <= 0){
      allRegions.push({country: study_area, years: years.map((y) => {return {year: y, type: conflict["y" + y], count: 1}})})
    } else {
      years.forEach((newYear) => {
        var yearExists = exists[0].years.filter((y) => y.year === newYear && y.type === conflict["y" + newYear])
        if (yearExists.length > 0){
          yearExists[0].count = yearExists[0].count + 1
        } else {
          exists[0].years.push({year: newYear, type: conflict["y" + newYear], count: 1})

        }
      })
    }
  }

  })

  var countries = allRegions.filter((a) => a.country.split(";").length <= 1);

  allRegions.filter((border) => border.country.split(";").length > 1).forEach((border) => {
    // console.log("---")
    border.country.split(";").forEach((countryName) => {
      // console.log(countryName, border.years)
      var exists = allRegions.filter((a) => a.country === countryName)
      // console.log(exists)

      if (exists.length <= 0){
        countries.push(
          {country: countryName,
            years: border.years
          })
      } else {
        border.years.forEach((newYear) => {
          var yearExists = exists[0].years.filter((y) => y.year === newYear.year && y.type === newYear.type)
          if (yearExists.length > 0){
            yearExists[0].count = yearExists[0].count + newYear.count
          } else {
            exists[0].years.push({year: newYear.year, type: newYear.type, count: newYear.count})
          }
        })
      }
    })
  })

  var finalfinal = []

  countries.forEach((regionCountry, index) => {
    const countryId = regionCountry.country;

    const firstAppearance = finalfinal.findIndex((c) => c.country === countryId)

    if (firstAppearance < 0){
      finalfinal.push(regionCountry);
    } else if (index !== firstAppearance){
      regionCountry.years.forEach((yearNuevo) => {
        const existe = finalfinal[firstAppearance].years.filter((yearExistente) => yearExistente.year === yearNuevo.year && yearNuevo.type === yearExistente.type )
        if (existe && existe.length > 0){
          existe[0].count = existe[0].count + yearNuevo.count
        } else {
          finalfinal[firstAppearance].years = [...finalfinal[firstAppearance].years, yearNuevo]
        }
      })

    }

  })


  return finalfinal.sort((a, b) => (a.type - b.type)).sort((a, b) => parseInt(a.year) - parseInt(b.year))
}

export function transformDataForGraphs(data){
  var all = []

  data.features.map((f) => f.properties).filter((a) => Object.keys(a).length > 5).forEach((conflict) => {
    var years = Object.keys(conflict).filter((k) => k[0] === "y" && k.length === 5).map((y) => y.substring(1))
    years.forEach((newYear) => {
      var yearExists = all.filter((y) => y.year === newYear && y.type === conflict["y" + newYear])
      if (yearExists.length > 0){
        yearExists[0].count = yearExists[0].count + 1
      } else {
        all.push({year: newYear, type: conflict["y" + newYear], count: 1})
      }
    })
  })

  return all.sort((a,b) => parseInt(a.year) - parseInt(b.year))
}

export function filterByType(data){
  return ["CH", "CL", "DH", "DL"].map((type) => {
    data = data.sort((a,b) => parseInt(a.year) - parseInt(b.year))
    const years = [...Array(28).keys()].map((y) => parseFloat(y + 1997)) // 1997 - 2024 change the chart years
    const values = years.map((y) => data.filter((d) => d.type === type && parseFloat(d.year) === y)).map((a) => a.length > 0 ? a[0].count : 0)
    return {type: type, years: years, values: values}
  })

}
